const config = {
    "broker": "1",
    "name": "Arabic",
    "registrationBundle": "arabic",
    "defaultCountryName": "Saudi Arabia",
    "defaultCountryCode": "SA",
    "fxgeoip_code": "AR",
    languageCode: 'ar',
    languageName:'Arabic',
    spokenLanguageCode:'ar',
    direction:'rtl',
    footerDisclaimer:'718',
    brand: 'iforex'
};
module.exports = config;